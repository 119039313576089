import { TREE_TYPE } from './constants';

function getInfos(sourceData = [], type = TREE_TYPE.MENU) {
  const infos = [];

  sourceData.forEach((item) => {
    const {
      flag: checked, id, name, menu_list: menus, button_list: buttons,
    } = item;
    const info = {
      id, name, title: name, type, checked: !!checked,
    };

    // 菜单或按钮权限只会出现一种
    if (menus) {
      info.children = getInfos(menus, TREE_TYPE.MENU);
    } else if (buttons) {
      info.children = getInfos(buttons, TREE_TYPE.BUTTON);
    }

    infos.push(info);
  });

  return infos;
}

export function getAuthTree(sourceData = []) {
  return getInfos(sourceData);
}
