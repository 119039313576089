export const ROLE_COLUMNS = [
  {
    title: '角色名',
    key: 'name',
    width: 160,
  },
  {
    title: '角色描述',
    key: 'info',
    width: 170,
  }, {
    title: '成员人数',
    key: 'user_ct',
    width: 120,
  }, {
    title: '创建人',
    key: 'nick_name',
    width: 160,
  }, {
    title: '创建时间',
    key: 'create_time',
    minWidth: 200,
  },
  {
    title: '操作',
    slot: 'action',
    width: 280,
    align: 'center',
    fixed: 'right',
  },
];

export const ALLOWED_PERMISSION = {
  EDIT: 'permission:role:edit',
  PERMISSION_BIND: 'permission:role:permi:bind',
  DELETE: 'permission:role:del',
};

export const ROLE_ACTION = {
  CREATE: 'permission:role:add',
  ...ALLOWED_PERMISSION,
};

export const ROLE_ACTION_TEXT = {
  [ROLE_ACTION.CREATE]: '新建角色',
  [ROLE_ACTION.EDIT]: '编辑角色',
  [ROLE_ACTION.PERMISSION_BIND]: '权限绑定',
};

export const TREE_TYPE = {
  BUTTON: 'button',
  MENU: 'menu',
};

export const TIPS = {
  NAME: '请输入角色名',
  INFO: '请输入角色描述',
};
