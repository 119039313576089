<template>
  <div class="role-manage">
    <app-block-flex>
      <app-spacing>
        <app-query-bar
          :queryConfig="queryConfig"
          v-model="queryData"
          @onSearch="fetchRoles"/>
      </app-spacing>
    </app-block-flex>
    <app-block-flex>
      <app-spacing>
        <Button
          v-if="permissions[ROLE_ACTION.CREATE]"
          type="warning"
          @click="() => onRoleAction(ROLE_ACTION.CREATE, true)">
          新建角色
        </Button>
      </app-spacing>
    </app-block-flex>
    <app-data-table
      :columns="ROLE_COLUMNS"
      :data="roles"
      :allowed-permissions="Object.values(ALLOWED_PERMISSION)"
      v-model="queryData"
      @onPageConfigChange="fetchRoles">
      <app-spacing
        spacing="sm"
        slot-scope="{ row }"
        slot="action">
        <Button
          v-if="permissions[ROLE_ACTION.PERMISSION_BIND]"
          type="primary"
          @click="() => onRoleAction(ROLE_ACTION.PERMISSION_BIND, true, row.id)">
          权限绑定
        </Button>
        <Button
          v-if="permissions[ROLE_ACTION.EDIT]"
          type="primary"
          @click="() => onRoleAction(ROLE_ACTION.EDIT, true, row.id)">
          编辑
        </Button>
        <Button
          v-if="permissions[ROLE_ACTION.DELETE]"
          type="error"
          @click="() => openDelete(row)"
          :disabled="!!row.default_flag">
          删除
        </Button>
      </app-spacing>
    </app-data-table>
    <app-modal  :value="deleteVisible"
                title="删除角色"
                @on-ok="deleteRole(roleModalData.id)"
                @on-cancel="() => onRoleAction(null, false)">
      确认删除{{ roleModalData.name }}吗？
    </app-modal>
    <app-modal
      :value="roleModalConfig.visible"
      :title="roleActionText"
      width="600"
      @on-ok="onModalConfirm"
      @on-cancel="() => onRoleAction(null, false)">
      <Form
        ref="roleForm"
        :model="roleModalData"
        :rules="roleModalRule"
        :label-width="80">
        <FormItem
          v-if="roleModalConfig.action && roleModalConfig.action !== ROLE_ACTION.CREATE">
          {{ roleModalData.id }}
        </FormItem>
        <FormItem
          label="角色名"
          prop="name">
          <Input
            v-if="roleModalConfig.action &&
               roleModalConfig.action !== ROLE_ACTION.PERMISSION_BIND"
            v-model="roleModalData.name"
            :placeholder="TIPS.NAME"/>
          <template v-else>
            {{ roleModalData.name }}
          </template>
        </FormItem>
        <FormItem
          v-if="roleModalConfig.action && roleModalConfig.action !== ROLE_ACTION.PERMISSION_BIND"
          label="角色描述"
          prop="info">
          <Input
            v-model="roleModalData.info"
            :placeholder="TIPS.INFO"/>
        </FormItem>
        <FormItem
          v-if="roleModalConfig.action === ROLE_ACTION.PERMISSION_BIND"
          label="权限选择">
          <Tree
            ref="permissionTreeRef"
            show-checkbox
            :data="roleModalConfig.permissionTree"
            multiple/>
        </FormItem>
      </Form>
    </app-modal>
  </div>
</template>

<script>
import RoleManageApi from './role-manage.api';
import {
  ROLE_COLUMNS,
  ROLE_ACTION,
  ROLE_ACTION_TEXT,
  ALLOWED_PERMISSION,
  TIPS,
} from './constants';
import { getAuthTree } from './role-manage.helper';

export default {
  name: 'role-manage',
  computed: {
    roleActionText() {
      return ROLE_ACTION_TEXT[this.roleModalConfig.action];
    },
  },
  data() {
    return {
      ROLE_COLUMNS,
      ROLE_ACTION,
      ALLOWED_PERMISSION,
      TIPS,
      queryConfig: [
        {
          fieldName: 'name',
          name: '角色名称',
        },
      ],
      queryData: {
        name: '',
        page: 1,
        limit: 10,
        count: 0,
      },
      roles: [],
      roleModalConfig: {
        visible: false,
        action: null,
        permissionTree: [],
      },
      roleModalRule: {
        name: [{ required: true, message: TIPS.NAME, trigger: 'change' }],
        info: [{ required: true, message: TIPS.INFO, trigger: 'change' }],
      },
      roleModalData: {
        id: NaN,
        name: '',
        info: '',
      },
      deleteVisible: false,
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    async fetchRoles() {
      const { name, page, limit } = this.queryData;

      const {
        count = 0,
        results = [],
      } = await RoleManageApi.getRoles(page, limit, name);
      this.roles = results;
      this.$set(this.queryData, 'count', count);
    },
    async openDelete(row) {
      this.roleModalData = {
        id: row.id,
        name: row.name,
      };
      this.deleteVisible = true;
    },
    async onRoleAction(action = ROLE_ACTION.CREATE, visible = false, roleId = NaN) {
      if (visible) {
        this.$refs.roleForm.resetFields();
      }
      this.deleteVisible = false;

      if (
        (action === ROLE_ACTION.EDIT || action === ROLE_ACTION.PERMISSION_BIND)
        && roleId?.toString()
      ) {
        const { name, info } = await RoleManageApi.getRoleDetail(roleId);
        this.roleModalData = {
          id: roleId,
          name,
          info,
        };
      } else {
        this.roleModalData = {
          id: NaN,
          name: '',
          info: '',
        };
      }

      if (action === ROLE_ACTION.PERMISSION_BIND && roleId?.toString()) {
        const sourceTree = await RoleManageApi.getRolePermission(roleId);
        const tree = getAuthTree(sourceTree);
        this.$set(this.roleModalConfig, 'permissionTree', tree);
      } else {
        this.$set(this.roleModalConfig, 'permissionTree', []);
      }

      this.roleModalConfig = {
        ...this.roleModalConfig,
        visible,
        action,
      };
    },
    async onModalConfirm() {
      this.$refs.roleForm.validate(async (valid) => {
        if (!valid) {
          return;
        }

        switch (this.roleModalConfig.action) {
          case ROLE_ACTION.CREATE:
            await this.createRole();
            break;
          case ROLE_ACTION.EDIT:
            await this.updateRole();
            break;
          case ROLE_ACTION.PERMISSION_BIND:
            await this.updatePermission();
            break;
          default:
            break;
        }

        await this.onRoleAction(null);
        await this.fetchRoles();
      });
    },
    async createRole() {
      const { name, info } = this.roleModalData;
      await RoleManageApi.createRole(name, info);
    },
    async updateRole() {
      const { id, name, info } = this.roleModalData;
      await RoleManageApi.updateRole(id, name, info);
    },
    async updatePermission() {
      const { id } = this.roleModalData;
      const permissions = this.$refs.permissionTreeRef
        .getCheckedNodes()
        .filter(({ children }) => !children)
        .map(({ id: selectedId }) => selectedId);
      await RoleManageApi.bindRolePermission(id, permissions);
    },
    async deleteRole(roleId) {
      await RoleManageApi.deleteRole(roleId);
      await this.onRoleAction(null, false);
      await this.fetchRoles();
    },
  },
};
</script>
