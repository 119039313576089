import axios from '@/plugins/axios';
import '@/plugins/vuex';
import store from '@/stores/store';

const { loginType } = store.getters;

class RoleManageApi {
  static getRoles(page = 1, limit = 10, name = '') {
    return axios({
      url: `/api/${loginType}/role/page`,
      data: { page, limit, name },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询角色列表',
        },
      },
    });
  }

  static getRoleDetail(id = NaN) {
    return axios({
      url: `/api/${loginType}/role/${id}/detail`,
      params: { id },
      method: 'get',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '获取角色详情',
        },
      },
    });
  }

  static createRole(name = '', info = '', permis = []) {
    return axios({
      url: `/api/${loginType}/role/add `,
      data: { name, info, permis },
      method: 'post',
      headers: {
        meta: {
          desc: '创建角色',
        },
      },
    });
  }

  static updateRole(id = NaN, name = '', info = '') {
    return axios({
      url: `/api/${loginType}/role/edit `,
      data: { id, name, info },
      method: 'post',
      headers: {
        meta: {
          desc: '编辑角色',
        },
      },
    });
  }

  static deleteRole(id) {
    return axios({
      url: `/api/sys/role/${id}/del`,
      method: 'post',
      headers: {
        meta: {
          desc: '删除角色',
        },
      },
    });
  }

  static getRolePermission(id = NaN) {
    return axios({
      url: '/api/sys/role/permission/tree',
      data: { role_id: id },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '获取权限树',
        },
      },
    });
  }

  static bindRolePermission(id = NaN, permissionIds = []) {
    return axios({
      url: '/api/sys/role/permission/bind',
      data: { role_id: id, permi_ids: permissionIds },
      method: 'post',
      headers: {
        meta: {
          desc: '绑定角色',
        },
      },
    });
  }
}

export default RoleManageApi;
